import { backendUrl, getAccessToken } from './config';

export async function getInvoices({ coin, network }) {
	const response = await fetch(
		`${backendUrl}/account/invoices/?coin=${coin}&network=${network}`,
		{
			method: 'GET',
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
		},
	);

	const responseBody = await response.json();

	return responseBody;
}
export async function getCredentials() {
	const response = await fetch(`${backendUrl}/account/site-credentials`, {
		method: 'GET',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}
export async function getCredential({ siteId, email }) {
	const response = await fetch(
		`${backendUrl}/account/site-credential?siteId=${siteId}&email=${encodeURIComponent(email)}`,
		{
			method: 'GET',
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
		},
	);

	const responseBody = await response.json();

	return responseBody;
}
export async function deleteCredential({ siteId, email }) {
	const response = await fetch(
		`${backendUrl}/account/site-credential?siteId=${siteId}&email=${encodeURIComponent(email)}`,
		{
			method: 'DELETE',
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
		},
	);

	const responseBody = await response.json();

	return responseBody;
}
export async function updateCredentials(credentials) {
	const map = {};

	credentials = credentials.filter((c) => {
		if (!map[c.email && c.email.toLowerCase()]) {
			map[c.email.toLowerCase()] = c;
			return true;
		}

		return false;
	});

	const response = await fetch(`${backendUrl}/account/site-credentials`, {
		method: 'POST',
		body: JSON.stringify(credentials),
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}
export async function getSites() {
	const response = await fetch(`${backendUrl}/account/sites`, {
		method: 'GET',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function getBalance(email) {
	const response = await fetch(`${backendUrl}/account/balance?email=${email || ''}`, {
		method: 'GET',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}
export async function getWalletBalances() {
	const response = await fetch(`${backendUrl}/account/wallet-balances`, {
		method: 'GET',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}
export async function getWorkerStats() {
	const response = await fetch(`${backendUrl}/account/worker-stats`, {
		method: 'GET',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}
export async function getDeviceBindingDevices() {
	const response = await fetch(`${backendUrl}/account/device-binding-devices`, {
		method: 'GET',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function getUser() {
	const response = await fetch(`${backendUrl}/account/user`, {
		method: 'GET',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function updateLinkSettings(body) {
	const response = await fetch(`${backendUrl}/account/link-settings`, {
		method: 'PUT',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
		body: JSON.stringify(body),
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function getLinkSettings() {
	const response = await fetch(`${backendUrl}/account/link-settings`, {
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function updateUser(body) {
	const response = await fetch(`${backendUrl}/account/user`, {
		method: 'PUT',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
		body: JSON.stringify(body),
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function getApiKey(config) {
	const refresh = config?.refresh;

	const response = await fetch(`${backendUrl}/account/api-key?refresh=${refresh || false}`, {
		method: 'GET',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function createInvoice({ coin, network, amount }) {
	const response = await fetch(`${backendUrl}/account/invoices/`, {
		method: 'POST',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
		body: JSON.stringify({
			coin,
			network,
			amount,
		}),
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function getIntegrationParameters() {
	const response = await fetch(`${backendUrl}/account/integration-parameters`, {
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function updateIntegrationParameters(body) {
	const response = await fetch(`${backendUrl}/account/integration-parameters`, {
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
		method: 'POST',
		body: JSON.stringify(body),
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function deleteIntegrationParameter({ keyId, keyName }) {
	const response = await fetch(
		`${backendUrl}/account/integration-parameters?keyName=${encodeURIComponent(keyName)}&keyId=${encodeURIComponent(keyId)}`,
		{
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
			method: 'DELETE',
		},
	);

	const responseBody = await response.json();

	return responseBody;
}
