import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

// import AdbIcon from '@mui/icons-material/Adb';
import { ThemeProvider, createTheme } from '@mui/material';
import {
	DarkMode,
	LightMode,
	HelpOutline,
	YouTube,
	LinkRounded,
	Splitscreen,
	AdminPanelSettings,
	Apple,
	PriceCheckOutlined,
	LaptopChromebook,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo2 from '../../../assets/img/SMP-02.png';
import Logo from '../../../assets/img/SMP-01.jpg';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import { getUser } from '../../../Api/account';

const DashboardHeader = ({ title }: any) => {
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const { user, setUser } = React.useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation();
	const [role, setRole] = React.useState<any>({});

	const isActive = (path: string) => {
		return location.pathname.includes(path);
	};

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const { themeStatus, darkModeStatus, setDarkModeStatus } = useDarkMode();

	const toggleDarkMode = () => {
		setDarkModeStatus(!darkModeStatus);
	};

	const handleLogout = () => {
		localStorage.clear();
		if (setUser) {
			setUser('');
		}
		window.location.reload(); // Consider using navigate for SPA behavior
	};

	React.useEffect(() => {
		(async () => {
			const userInfo = await getUser();
			const userPriv = userInfo.role;

			setRole(userPriv);
		})();
	}, []);

	return (
		<AppBar position='static'>
			<Container sx={{ maxWidth: '100vw !important', padding: '0 20px', margin: 0 }}>
				<Toolbar variant='dense' disableGutters>
					{/* Mobile Box. */}
					<Box
						sx={{
							display: { xs: 'flex', md: 'none', flexGrow: 0 },
						}}>
						<IconButton
							size='small'
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							color='inherit'>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}>
							<MenuItem key={1} onClick={() => navigate('/faq')}>
								<HelpOutline sx={{ marginRight: 1 }} />
								<Typography textAlign='center'>Faq</Typography>
							</MenuItem>
							<MenuItem key={2} onClick={() => navigate('/guides')}>
								<Typography textAlign='center'>
									<YouTube sx={{ marginRight: 1 }} />
									Guides
								</Typography>
							</MenuItem>
							<MenuItem key={1} onClick={() => navigate('/my-links')}>
								<LinkRounded sx={{ marginRight: 1 }} />
								<Typography textAlign='center'>My Links</Typography>
							</MenuItem>
							<MenuItem key={1} onClick={() => navigate('/split')}>
								<Splitscreen sx={{ marginRight: 1 }} />
								<Typography textAlign='center'>Split</Typography>
							</MenuItem>
							<MenuItem key={1} onClick={() => navigate('/pricing')}>
								<PriceCheckOutlined sx={{ marginRight: 1 }} />
								<Typography textAlign='center'>Pricing</Typography>
							</MenuItem>
							{role === 'admin' && (
								<MenuItem key={1} onClick={() => navigate('/admin-panel')}>
									<AdminPanelSettings sx={{ marginRight: 1 }} />
									<Typography textAlign='center'>Admin Panel</Typography>
								</MenuItem>
							)}
						</Menu>
					</Box>

					{/* <AdbIcon sx={{ display: 'flex', mr: 1 }} /> */}
					<Typography
						variant='h6'
						noWrap
						component='a'
						onClick={() => navigate('/')}
						sx={{
							mr: 2,
							display: 'flex',
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '1px',
							color: 'inherit',
							textDecoration: 'none',
							cursor: 'pointer',
						}}>
						<img
							style={{
								width: '140px',
							}}
							src={darkModeStatus ? Logo2 : Logo}
						/>
					</Typography>

					<Box
						sx={{
							flexGrow: 1,
							display: { xs: 'none', md: 'flex' },
							gap: 2,
						}}>
						<Button
							key={1}
							onClick={() => navigate('/faq')}
							disabled={isActive('/faq')}
							sx={{
								my: 2,
								color: isActive('/faq') ? 'secondary.main' : 'white', // Change color when active
								backgroundColor: isActive('/faq')
									? 'rgba(25, 118, 210, 0.08)'
									: 'transparent', // Optional background color when active
								display: 'block',
							}}>
							<HelpOutline sx={{ marginRight: 1 }} />
							Faq
						</Button>
						<Button
							key={1}
							onClick={() => navigate('/guides')}
							sx={{ my: 2, color: 'white', display: 'block' }}>
							<YouTube sx={{ marginRight: 1 }} />
							Guides
						</Button>

						<Button
							key={1}
							onClick={() => navigate('/my-links')}
							disabled={isActive('/my-links')}
							sx={{
								my: 2,
								color: isActive('/my-links') ? 'secondary.main' : 'white', // Change color when active
								backgroundColor: isActive('/my-links')
									? 'rgba(25, 118, 210, 0.08)'
									: 'transparent', // Optional background color when active
								display: 'block',
							}}>
							<LinkRounded sx={{ marginRight: 1 }} />
							My Links
						</Button>
						<Button
							key={1}
							onClick={() => navigate('/tm-app-manual')}
							disabled={isActive('/tm-app-manual')}
							sx={{
								my: 2,
								color: isActive('/tm-app-manual') ? 'secondary.main' : 'white', // Change color when active
								backgroundColor: isActive('/tm-app-manual')
									? 'rgba(25, 118, 210, 0.08)'
									: 'transparent', // Optional background color when active
								display: 'block',
							}}>
							<Apple sx={{ marginRight: 1 }} />
							TM App (Manual)
						</Button>
						<Button
							key={1}
							onClick={() => navigate('/split')}
							disabled={isActive('/split')}
							sx={{
								my: 2,
								color: isActive('/split') ? 'secondary.main' : 'white', // Change color when active
								backgroundColor: isActive('/split')
									? 'rgba(25, 118, 210, 0.08)'
									: 'transparent', // Optional background color when active
								display: 'block',
							}}>
							<Splitscreen sx={{ marginRight: 1 }} />
							Split Links
						</Button>
						<Button
							key={1}
							onClick={() => navigate('/pricing')}
							disabled={isActive('/pricing')}
							sx={{
								my: 2,
								color: isActive('/pricing') ? 'secondary.main' : 'white', // Change color when active
								backgroundColor: isActive('/pricing')
									? 'rgba(25, 118, 210, 0.08)'
									: 'transparent', // Optional background color when active
								display: 'block',
							}}>
							<PriceCheckOutlined sx={{ marginRight: 1 }} />
							Pricing
						</Button>
						{role === 'admin' && (
							<Button
								key={1}
								onClick={() => navigate('/admin-panel')}
								disabled={isActive('/admin-panel')}
								sx={{
									my: 2,
									color: isActive('/admin-panel') ? 'secondary.main' : 'white', // Change color when active
									backgroundColor: isActive('/admin-panel')
										? 'rgba(25, 118, 210, 0.08)'
										: 'transparent', // Optional background color when active
									display: 'block',
								}}>
								<AdminPanelSettings sx={{ marginRight: 1 }} />
								Admin Panel
							</Button>
						)}

						<Button
							key={1}
							onClick={() => navigate('/privatetabs')}
							disabled={isActive('/privatetabs')}
							sx={{
								my: 2,
								px: 3,
								color: 'white',
								backgroundColor: isActive('/privatetabs')
									? 'rgba(25, 118, 210, 0.08)'
									: '#d32f2f', // Red for the "new" effect
								border: '1px solid',
								borderColor: '#d32f2f', // Match the "new" red
								borderRadius: '20px', // Tag-like rounded edges
								display: 'flex',
								alignItems: 'center',
								textTransform: 'none',
								fontWeight: 600,
								fontSize: '0.875rem',
								position: 'relative',
								'&:hover': {
									backgroundColor: '#b71c1c', // Darker red on hover
								},
							}}>
							<LaptopChromebook sx={{ marginRight: 1 }} />
							Multi-session browser
							<span
								style={{
									position: 'absolute',
									top: '-8px',
									right: '-8px',
									backgroundColor: '#ff1744',
									color: 'white',
									fontSize: '0.7rem',
									fontWeight: 700,
									padding: '2px 6px',
									borderRadius: '12px',
								}}>
								NEW
							</span>
						</Button>
					</Box>

					<Box sx={{ marginLeft: 'auto', flexGrow: 0 }}>
						<IconButton color='inherit' onClick={toggleDarkMode} sx={{ mx: 1 }}>
							{darkModeStatus ? <LightMode /> : <DarkMode />}{' '}
							{/* Adjust icon based on theme */}
						</IconButton>
						<Tooltip title='Open settings'>
							<IconButton size='small' onClick={handleOpenUserMenu} sx={{ p: 0 }}>
								<Avatar alt={user} style={{ width: '30px', height: '30px' }} />
							</IconButton>
						</Tooltip>
						<Menu
							sx={{ mt: '45px' }}
							id='menu-appbar'
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}>
							<MenuItem key={1} onClick={() => navigate('/profile')}>
								<Typography textAlign='center'>Profile</Typography>
							</MenuItem>
							<MenuItem key={1} onClick={() => navigate('/credentials')}>
								<Typography textAlign='center'>Credentials</Typography>
							</MenuItem>
							<MenuItem key={2} onClick={handleLogout}>
								<Typography textAlign='center'>Logout</Typography>
							</MenuItem>
						</Menu>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default DashboardHeader;
