import React from 'react';
import { Button, AppBar, Toolbar, Typography } from '@mui/material';
import PrivatetabsBanner from '../../assets/img/privatetabs.gif';

const TopBanner = () => {
	return (
		<div
			style={{
				padding: '10px 0',
				maxWidth: '800px',
				margin: 'auto',
			}}>
			<a href='https://securemypass.com/privatetabs' target='_blank' rel='noreferrer'>
				<img
					src={PrivatetabsBanner}
					alt='SecureMyPass'
					style={{ width: '100%', height: 'auto' }}
				/>
			</a>
		</div>
	);
};

export default TopBanner;
