import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Fab, Link, TextField, Tooltip, Typography } from '@mui/material';
import { Add, Email, Payment, Refresh, Support, Telegram } from '@mui/icons-material';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Popovers from '../../../components/bootstrap/Popovers';
import Badge from '../../../components/bootstrap/Badge';
import Modal, { ModalBody, ModalHeader } from '../../../components/bootstrap/Modal';
import Alert from '../../../components/bootstrap/Alert';
import Topup from '../../../components/Topup';
import { createInvoice, getApiKey, getBalance, getUser, updateUser } from '../../../Api/account';
import showNotification from '../../../components/extras/showNotification';
import LatestLinksTable from '../../../components/LatestLinksTable';
import AddonInstall from '../../../components/AddonInstall';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';

const DashboardPage = () => {
	const { darkModeStatus } = useDarkMode();

	return (
		<PageWrapper style={{ margin: 0, padding: 0 }} title='PrivateTabs' isProtected={false}>
			<div
				style={{
					textAlign: 'center',
					padding: '20px',
					fontFamily: 'Arial, sans-serif',
					backgroundColor: '#333',
					color: '#FFF',
				}}>
				<div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
					<h2
						style={{
							fontSize: '2.5rem',
							marginBottom: '20px',
							fontWeight: '600',
							textAlign: 'center',
							lineHeight: '1.2',
						}}>
						Big News: We’re Focusing on <b>Private Tabs</b>!
					</h2>

					<p
						style={{
							fontSize: '1.25rem',
							lineHeight: '1.8',
							marginBottom: '25px',
							textAlign: 'justify',
						}}>
						To provide you with the smoothest, most reliable experience, we’ve decided
						to make <b>Private Tabs</b> our recommended <b>multi-session browser.</b>
					</p>

					<h3
						style={{
							fontSize: '2rem',
							marginBottom: '15px',
							fontWeight: '700',
							textAlign: 'center',
							lineHeight: '1.3',
						}}>
						Why the Change?
					</h3>
					<p
						style={{
							fontSize: '1.15rem',
							lineHeight: '1.8',
							marginBottom: '25px',
							textAlign: 'justify',
						}}>
						While we still support Chrome and Firefox as normal browsers, we decided to
						stop supporting all other multi-session browsers due to compatibility
						issues. <b>Private Tabs</b> ensures 100% compatibility with all SecureMyPass
						features, offering unparalleled ease, stability, and protection for your
						orders.
					</p>

					<h3
						style={{
							fontSize: '2rem',
							marginBottom: '15px',
							fontWeight: '700',
							textAlign: 'center',
							lineHeight: '1.3',
						}}>
						Exclusive Perks for SMP Users!
					</h3>
					<ul
						style={{
							listStyle: 'none',
							padding: '0',
							fontSize: '1.15rem',
							lineHeight: '1.8',
						}}>
						<li style={{ marginBottom: '15px' }}>
							🌟 <b>2-day free trial</b>—no risks, just results!
						</li>
						<li style={{ marginBottom: '15px' }}>
							🌟 <b>50% off</b> your first month using code <b>SMP</b>.
						</li>
						<li style={{ marginBottom: '15px' }}>
							🌟 <b>$100 in SMP credits</b> for premium users (sent $100+ before)
							during your trial!
						</li>
					</ul>

					<p
						style={{
							fontSize: '1.15rem',
							lineHeight: '1.8',
							marginBottom: '20px',
							textAlign: 'justify',
						}}>
						Maximize your success—switch to <b>Private Tabs</b> today!
					</p>
				</div>
			</div>

			<div style={{ margin: 0, padding: 0, width: '100%' }}>
				<div>
					<style>
						{`
          .responsive-iframe {
            width: 100%;
            border: none;
          }

          /* Large screens (e.g., desktops) */
          @media (min-width: 1200px) {
            .responsive-iframe {
              height: 3000px;
            }
          }

          /* Medium screens (e.g., tablets) */
          @media (min-width: 785px) and (max-width: 1199px) {
            .responsive-iframe {
              height: 5600px;
            }
          }

          /* Small screens (e.g., phones) */
          @media (max-width: 784px) {
            .responsive-iframe {
              height: 6000px;
            }
          }
        `}
					</style>
					{darkModeStatus ? (
						<iframe
							className='responsive-iframe'
							style={{ width: '100%' }}
							src='https://referrals.privatetabs.com/smp-dark'
						/>
					) : (
						<iframe
							className='responsive-iframe'
							style={{ width: '100%' }}
							src='https://referrals.privatetabs.com/smp-light'
						/>
					)}
				</div>
			</div>
		</PageWrapper>
	);
};

export default DashboardPage;
